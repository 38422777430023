import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { Subject, takeUntil } from 'rxjs';
import { CpRoutes } from '../../../cp-route-constants';
import { OutboundService } from '../../../services/outbound-service/outbound.service';
import { SnackBarService } from '../../../services/snackBar-services/snack-bar.service';
@Component({
  selector: 'app-change-address-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    FloatLabelModule,
    CommonModule,
  ],
  templateUrl: './change-address-form.component.html',
  styleUrl: './change-address-form.component.scss',
  providers: [DialogService, DynamicDialogRef],
})
export class ChangeAddressFormComponent {
  outboundService = inject(OutboundService);
  snackBarservice = inject(SnackBarService);
  addressForm: FormGroup;
  isUpdating: boolean;
  isDisplay = false;
  public order: any;
  address: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  changeAddressTitle = 'Change address';
  customerNameLabel = 'Customer Name';
  newAddressLabel = 'Enter New Address';
  firstNameLabel = 'First name';
  firstNameValidatorFirst = 'First Name is required.';
  firstNameValidatorSecond = 'First Name must be at least 2 characters long.';
  firstNameValidatorThird =
    'First Name should only contain letters and spaces..';
  lastNameLabel = 'Last name';
  lastNameValidatorFirst = 'Last Name is required.';
  lastNameValidatorSecond = 'Last Name must be at least 2 characters long.';
  lastNameValidatorThird = 'Last Name should only contain letters and spaces..';
  addressLineOneLabel = 'Address line 1';
  addressLineOneValidatorsFirst = 'Address Line1 is required';
  addressLineOneValidatorsSecond = 'Minimum 3 characters are required.';
  addressLineTwoLabel = 'Address line 2';
  addressLineTwoValidatorsFirst = 'Minimum  characters are required.';
  postCodeLabel = 'Post Code';
  postCodeValidatorsFirst = 'Postal Code is required.';
  postCodeValidatorsSecond = 'Minimun 2 number is required';
  postCodeValidatorsThird = 'Post code should only contain numbers .';
  cityLabel = 'City';
  cityValidatorsFirst = 'City is required';
  cityValidatorsSecond = 'Minimum 2 character are required.';
  countryLabel = 'Country';
  countryValidatorsFirst = 'Country is required';
  countryValidatorsSecond = 'Minimum 2 character are required.';
  errorMessage = 'Please try again or contact your administrator.';
  successMessage = 'Address changed successfully';
  constructor(
    public fb: FormBuilder,
   public router: Router
  ) {
    this.addressForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('[A-Za-z ]+'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[a-zA-Z ]*$'),
        ],
      ],
      addressLine1: ['', [Validators.required, Validators.minLength(3)]],
      addressLine2: ['', [Validators.minLength(2)]],
      city: ['', [Validators.required, Validators.minLength(2)]],
      postalCode: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[0-9]{5}$'),
        ],
      ],
      countryName: ['', [Validators.required, Validators.minLength(2)]],
    });
  }
  ngOnInit() {
    this.order = history.state?.orderId;
    const payload = {
      company_name: sessionStorage.getItem('companyname')?.trim(),
      orderID: this.order,
    };
    this.isUpdating = false;
    this.outboundService
      .getOutboundOrderDetails(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.address = data.response.shipToAddress;
        this.addressForm.patchValue(this.address);
      });
    this.isDisplay = true;
  }
  redirectToObOrderDetails() {
    const eventData = {
      orderId: this.order,
    };
    this.router.navigate([CpRoutes.OrderDetailsInfo], {
      state: { eventData },
    });
  }
  onCancel() {
    this.redirectToObOrderDetails();
  }
  onSubmit() {
    if (this.addressForm.valid) {
      this.isUpdating = true;
      const payloadData = {
        company_name: sessionStorage.getItem('companyname')?.trim(),
        orderID: this.order,
        shipToAddress: {
          salutation: this.address.salutation,
          firstName: this.addressForm.controls['firstName'].value,
          lastName: this.addressForm.controls['lastName'].value,
          addressLine1: this.addressForm.controls['addressLine1'].value,
          addressLine2: this.addressForm.controls['addressLine2'].value,
          city: this.addressForm.controls['city'].value,
          postalCode: this.addressForm.controls['postalCode'].value,
          stateOrProvince: '',
          countryCode: this.address.countryCode,
          countryName: this.address.countryName,
          contactPhone: this.address.contactPhone,
          contactMobilePhone: this.address.contactPhone,
          externalRef: this.address.externalRef,
        },
      };
      if (this.order !== null && this.order !== undefined) {
        this.outboundService
          .updateAddress(payloadData)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (data: any) => {
              this.isUpdating = false;
              if (data.status === 'success') {
                this.snackBarservice.showSuccess('Done', this.successMessage);
              } else {
                this.snackBarservice.showError(
                  'API Failure',
                  this.errorMessage
                );
              }
              this.redirectToObOrderDetails();
              this.isDisplay = false;
            },
            error: () => {
              this.redirectToObOrderDetails();
              this.isDisplay = false;
            },
          });
      }
    }
  }
}
